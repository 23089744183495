<template>
    <div class="m-2" :style="`direction:`+lang.dir">
        <b-sidebar backdrop id="pview_invoice" width="85%" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right :title="lang.view_invoice" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:150px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span>{{lang.view_invoice}}</span>
                    </div>
                    <div @click="hide" id="hideVinvo" style="text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer;width:100px;" class="btn-danger btn-sm">
                        <span>{{lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <div :style="`direction:`+lang.dir" class="m-2">
                    <v-col cols="12" v-if="showButtons">
                        <div class="backBlack" style="padding:0px;width:100%;text-align:center;">
                            <b-button v-if="invoice.cardid == 0 && invoice.remain > 0 && (parseFloat(invoice.cancel_by) == 0 || parseFloat(invoice.cancel_by).toString() == 'NaN')" style="border:2px solid #fff !important;font-size:1.1rem;background:green !important;width:150px;color:#fff;" class="mytab2 btn btn-sm btn-success m-2" @click="invpayment()" v-b-toggle.addPayments>{{lang.make_payment}}</b-button> 
                            <b-button v-if="invoice.status > 0 && (parseFloat(invoice.cancel_by) == 0 || parseFloat(invoice.cancel_by).toString() == 'NaN')" style="border:2px solid #fff !important;font-size:1.1rem;background:blue !important;color:#fff;width:130px;font-weight: bolder;" class="mytab2 btn btn-sm btn-danger m-2" @click="returnNow(0)" v-b-toggle.returnInvoice>{{lang.invoice_return}}</b-button> 
                            <b-button style="border:2px solid #fff !important;font-size:1.1rem;background:red !important;width:150px;color:#fff;" class="mytab2 btn btn-sm btn-success m-2" @click="changeNote()" v-b-toggle.changeNote>{{lang.changeNote}}</b-button> 
                            <b-button class="mytab1 btn btn-sm btn-default m-2" style="border:2px solid #fff !important;width:130px;background:green !important;color:#fff !important;font-size:1.1rem;" @click="printPDF(invid,1)">{{lang.print_invoice}} </b-button> 
                            <b-button class="mytab1 btn btn-sm btn-default m-2" style="border:2px solid #fff !important;width:130px;background:darkslategray !important;font-size:1.1rem;color:#fff;" @click="printInvo(d)">{{lang.view_pdf}}</b-button> 
                            <img :src="`../whatsappicon.png`" style="border:2px solid #fff !important;width:120px;margin-inline-start: 10px;height: 38px;border-radius: 5px;cursor:pointer" @click="openWhatsAPP()" v-b-toggle.send_invoice_whats />
                        </div>
                    </v-col>
                </div>
                <div :style="`direction:`+lang.dir" class="m-2">
                    <v-row>
                        <v-col cols="12" class="text-center" style="font-size:1.1rem;"> {{ invoice._invtype }}</v-col>
                        <v-col cols="12" md="6" sm="12">
                            <b-table-simple :style="`direction:`+lang.dir">
                                <thead>
                                    <tr>
                                        <th style="width:20% !important;height: 10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;">{{lang.invoiceno}}</th>
                                        <!-- <th style="background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;width:33.33%">{{lang.invoice_type}}</th> -->
                                        <th style="width:20% !important;height: 10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;">{{lang.invoice_date}}</th>
                                        <th style="width:20% !important;height: 10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;">{{lang.invoice_date}}</th>
                                        <th style="width:20% !important;height: 10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;">{{lang.paid}}</th>
                                        <th style="width:20% !important;height: 10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;">{{lang.remain}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="height: 10px !important;padding:2px !important;border:1px solid #555;text-align:center;width:8.33%">{{ invoice.invoice_number }}</td>
                                        <!-- <td style="border:1px solid #555;text-align:center;" v-if="invoice.total > 0">{{ invoice._invtype }}</td> -->
                                        <!-- <td style="border:1px solid #555;text-align:center;background:red;color:#fff" v-else>{{ invoice._invtype }}</td> -->
                                        <td style="height: 10px !important;padding:2px !important;border:1px solid #555;text-align:center;">{{ invoice.inv_date.substring(0,10) }}</td>
                                        <td style="height: 10px !important;padding:2px !important;border:1px solid #555;text-align:center;">{{ invoice.inv_date.substring(11) }}</td>
                                        <td style="height: 10px !important;padding:2px !important;border:1px solid #555;text-align:center;">{{ invoice.paid }}</td>
                                        <td style="height: 10px !important;padding:2px !important;border:1px solid #555;text-align:center;">{{ invoice.remain }}</td>
                                    </tr>
                                </tbody>
                            </b-table-simple>
                            <b-table-simple v-if="invoice.cancel_by">
                                <thead>
                                    <tr>
                                        <th style="border:1px solid #555;text-align:center;height: 10px !important;padding:2px !important;">{{lang.return_date}}</th>
                                        <th style="border:1px solid #555;text-align:center;height: 10px !important;padding:2px !important;">{{lang.return_time}}</th>
                                        <th style="border:1px solid #555;text-align:center;height: 10px !important;padding:2px !important;">{{lang.return_case}}</th>
                                    </tr>
                                    <tr>
                                        <td style="height: 10px !important;padding:2px !important;border:1px solid #555;text-align:center;width:33.33%">{{ invoice.cancel_date }}</td>
                                        <td style="height: 10px !important;padding:2px !important;border:1px solid #555;text-align:center;width:33.33%">{{ invoice.cancel_date  }}</td>
                                        <td style="height: 10px !important;padding:2px !important;border:1px solid #555;text-align:center;width:33.33%">{{ invoice.cancel_reson }}</td>
                                    </tr>
                                </thead>
                            </b-table-simple>
                        </v-col>
                        <v-col cols="12" md="6" sm="12">
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th style="height:10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;">{{lang.customer_type}}</th>
                                        <th style="height:10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;">{{lang.customer_name}}</th>
                                        <th style="height:10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;" v-if="invoice.customer_type == 2">{{lang.company_vatid}}</th>
                                        <!-- <th style="height:10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;" v-if="invoice.customer_type == 1">{{lang.id_number}}</th> -->
                                        <!-- <th style="height:10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;" v-if="invoice.customer_type == 1">{{lang.owner_name}}</th> -->
                                        <th style="height:10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;">{{lang.mobile}}</th>
                                        <th style="height:10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;" v-if="invoice.customer_type == 3">{{lang.entity_name}}</th>
                                        <th style="height:10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;" v-if="invoice.customer_type == 3">{{lang.branch_name}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="height:10px !important;padding:2px !important;color:#fff !important;border:1px solid #555;text-align:center;background:red;">{{ custType(invoice.customer_type) }}</td>
                                        <td style="height:10px !important;padding:2px !important;border:1px solid #555;text-align:center;" v-if="invoice.customer_type != 1 && invoice.customer_type != 2 && invoice.customer_type != 3">{{ invoice.full_name }}</td>
                                        <td style="height:10px !important;padding:2px !important;border:1px solid #555;text-align:center;" v-if="invoice.customer_type == 1">{{ invoice.full_name }}</td>
                                        <!-- <td style="height:10px !important;padding:2px !important;border:1px solid #555;text-align:center;" v-if="invoice.customer_type == 1">{{ invoice.id_number }}</td>
                                        <td style="height:10px !important;padding:2px !important;border:1px solid #555;text-align:center;" v-if="invoice.customer_type == 1">{{ invoice.owner_name }}</td> -->
                                        <td style="height:10px !important;padding:2px !important;border:1px solid #555;text-align:center;" v-if="invoice.customer_type == 2">{{ invoice.company_name }}</td>
                                        <td style="height:10px !important;padding:2px !important;border:1px solid #555;text-align:center;" v-if="invoice.customer_type == 3">{{ invoice.entity_name }}</td>
                                        <td style="height:10px !important;padding:2px !important;border:1px solid #555;text-align:center;" v-if="invoice.customer_type == 2">{{invoice.vatid}}</td>
                                        <td style="height:10px !important;padding:2px !important;border:1px solid #555;text-align:center;">{{ invoice.mobile }}</td>
                                        <td style="height:10px !important;padding:2px !important;border:1px solid #555;text-align:center;" v-if="invoice.customer_type == 3">{{ invoice.entity_name }}</td>
                                        <td style="height:10px !important;padding:2px !important;border:1px solid #555;text-align:center;" v-if="invoice.customer_type == 3">{{ invoice.branch_name }}</td>
                                    </tr>
                                </tbody>
                            </b-table-simple>
                            <b-table-simple v-if="invoice.customer_type == 3">
                                <thead>
                                    <tr>
                                        <th style="height:10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;">{{lang.order_number}}</th>
                                        <th style="height:10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;">{{lang.po_number}}</th>
                                        <th style="height:10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;">{{lang.po_date}}</th>
                                        <th style="height:10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;">{{lang.request_number}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="height:10px !important;padding:2px !important;color:#fff !important;border:1px solid #555;text-align:center;background:red;">{{ invoice.order_number }}</td>
                                        <td style="height:10px !important;padding:2px !important;color:#fff !important;border:1px solid #555;text-align:center;background:red;">{{ invoice.po_number }}</td>
                                        <td style="height:10px !important;padding:2px !important;color:#fff !important;border:1px solid #555;text-align:center;background:red;">{{ invoice.po_date }}</td>
                                        <td style="height:10px !important;padding:2px !important;color:#fff !important;border:1px solid #555;text-align:center;background:red;">{{ invoice.request_number }}</td>
                  
                                    </tr>
                                </tbody>
                            </b-table-simple>
                            <b-table-simple v-if="invoice.company_name != null && invoice.company_name != ''">
                                <thead>
                                    <tr>
                                        <th style="height:10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;">{{lang.crt_number}}</th>
                                        <th style="height:10px !important;padding:2px !important;background:#000 !important;color:#fff !important;border:1px solid #555;text-align:center;">{{lang.address}}</th>
                                    </tr>
                                    <tr>
                                        <td style="height:10px !important;padding:2px !important;border:1px solid #555;text-align:center;">{{ invoice.crt_number }}</td>
                                        <td style="height:10px !important;padding:2px !important;border:1px solid #555;text-align:center;">{{ invoice.address }}</td>
                                    </tr>
                                </thead> 
                            </b-table-simple>
                        </v-col>
                    </v-row>
                    <div v-if="$store.state.licenseType.cars || $store.state.licenseType.parts || $store.state.licenseType.carinfo">
                        <div class="text-center mt-2">{{ lang.cars_list }}</div>
                        <v-row v-if="invoice.cars_info && invoice.cars_info.length != 0">
                            <v-col cols="12" md="6" sm="12" v-for="(item,index) in invoice.cars_info" :key="index">
                                <b-table-simple >
                                    <thead>
                                        <tr>
                                            <th style="background:#000 !important;color:#fff !important;width:15%;padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{lang.plate_number}}</th>
                                            <th style="background:#000 !important;color:#fff !important;width:15%;padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{lang.car_model}}</th>
                                            <th style="background:#000 !important;color:#fff !important;width:15%;padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{lang.car_year}}</th>
                                            <th style="background:#000 !important;color:#fff !important;width:15%;padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{ lang.car_color }}</th>
                                            <th style="background:#000 !important;color:#fff !important;width:40%;padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{lang.vin_number}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{ item.plate_number }}</td>
                                            <td style="padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{ item.model }}</td>
                                            <td style="padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{ item.made_year }}</td>
                                            <td style="padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{ item.color }}</td>
                                            <td style="padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{ item.vin }}</td>
                                        </tr>
                                    </tbody>
                                </b-table-simple>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12">
                                <b-table-simple >
                                    <thead>
                                        <tr v-if="$store.state.licenseType.cars || $store.state.licenseType.parts || $store.state.licenseType.carinfo">
                                            <th style="width:120px;padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{lang.plate_number}}</th>
                                            <td style="padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{ invoice.plate_number }}</td>
                                            <th style="width:120px;padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{lang.car_model}}</th>
                                            <td style="padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{ invoice.model }}</td>
                                        </tr>
                                        <tr v-if="$store.state.licenseType.cars || $store.state.licenseType.parts || $store.state.licenseType.carinfo">
                                            <th style="width:120px;padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{lang.engine_meter}}</th>
                                            <td style="padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{ invoice.engine_meter }}</td>
                                            <th style="width:120px;padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{lang.next_oil_change}}</th>
                                            <td style="padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{ invoice.next_oil_change }}</td>
                                        </tr>
                                        <tr v-if="$store.state.licenseType.cars || $store.state.licenseType.parts">
                                            <th style="padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{lang.car_year}}</th>
                                            <td style="padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{ invoice.manufacturing_year }}</td>
                                            <th style="padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{lang.car_color}}</th>
                                            <td style="padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{ invoice.color }}</td>
                                        </tr>
                                        <tr v-if="$store.state.licenseType.cars || $store.state.licenseType.parts">
                                            <th style="padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{lang.vin_number}}</th>
                                            <td colspan="3" style="padding:2px;height:10px !important;border:1px solid #555;text-align:center;">{{ invoice.vin }}</td>
                                        </tr>
                                    </thead>
                                </b-table-simple>
                            </v-col>
                        </v-row>
                    </div>

                </div>
                <div :style="`direction:`+lang.dir" class="m-2">
                    <h6 class="h6title text-center" style="font-weight:bolder;background:#000 !important;color:#fff !important;">{{lang.invoice_details}}</h6>
                    <b-table-simple>
                        <thead>
                            <tr>
                                <!-- <th v-if="returnIt" style="text-align:center;width:50px;">
                                    
                                </th> -->
                                <th style="text-align:center;height:10px !important;padding:2px !important;width:110px;" v-if="$store.state.licenseType.cars && $store.state.licenseType.showCarPlate">{{lang.plate_number}}</th>
                                <th style="text-align:center;height:10px !important;padding:2px !important;width:110px;" v-if="$store.state.licenseType.showItemCode">{{lang.item_code}}</th>
                                <th style="text-align:center;height:10px !important;padding:2px !important;">{{lang.description}}</th>
                                <th style="text-align:center;height:10px !important;padding:2px !important;width:100px;">{{lang.qtty}}</th>
                                <th style="text-align:center;height:10px !important;padding:2px !important;width:100px;">{{lang.price}}</th>
                                <th style="text-align:center;height:10px !important;padding:2px !important;width:100px;">{{lang.total}}</th>
                                <th style="text-align:center;height:10px !important;padding:2px !important;width:100px;">{{lang.vat}} 15%</th>
                                <th style="text-align:center;height:10px !important;padding:2px !important;width:100px;">{{lang.ftotal}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in invoice.rows" :key="index">
                                <!-- <td style="text-align:center;" v-if="returnIt">
                                    <input type="checkbox" v-if="item.status > 0" :value="item.id" @click="selThis(item)">
                                </td> -->
                                <td style="text-align:center;height:10px !important;padding:2px !important;" v-if="$store.state.licenseType.cars && $store.state.licenseType.showCarPlate">{{item.plate_number}}</td>
                                <td style="text-align:center;height:10px !important;padding:2px !important;" v-if="$store.state.licenseType.showItemCode">{{item.itemcode}}</td>
                                <td style="text-align:center;height:10px !important;padding:2px !important;">{{item.item_name}}</td>
                                <td style="text-align:center;height:10px !important;padding:2px !important;">{{item.qty}}</td>
                                <td style="text-align:center;height:10px !important;padding:2px !important;">{{item.item_price}}</td>
                                <td style="text-align:center;height:10px !important;padding:2px !important;">{{item.total}}</td>
                                <td style="text-align:center;height:10px !important;padding:2px !important;">{{item.vat}}</td>
                                <td style="text-align:center;height:10px !important;padding:2px !important;">{{item.ftotal}}</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                    <b-table-simple style="width:400px;margin:auto;margin-inline-end:0px !important;">
                        <thead>
                            <tr>
                                <th class="text-end" style="padding:2px !important;height:10px !important;">{{lang.total_b_d}}</th>
                                <td style="width:25%;border:1px solid #ccc;text-align:center;padding:2px !important;height:10px !important;">{{invoice.total}}</td>
                            </tr>
                            <tr>
                                <th class="text-end" style="padding:2px !important;height:10px !important;">{{lang.discount}}</th>
                                <td style="width:25%;border:1px solid #ccc;text-align:center;padding:2px !important;height:10px !important;">{{isNaN(parseFloat(invoice.discount)) ? 0 : parseFloat(invoice.discount)}}</td>
                            </tr>
                            <tr>
                                <th class="text-end" style="padding:2px !important;height:10px !important;">{{lang.total_exclusive_vat}}</th>
                                <td style="width:25%;border:1px solid #ccc;text-align:center;padding:2px !important;height:10px !important;">{{invoice.total}}</td>
                            </tr>
                            <tr>
                                <th class="text-end" style="padding:2px !important;height:10px !important;">{{lang.vat}} 15%</th>
                                <td style="width:25%;border:1px solid #ccc;text-align:center;padding:2px !important;height:10px !important;">{{invoice.vat}}</td>
                            </tr>
                            <tr>
                                <th class="text-end" style="padding:2px !important;height:10px !important;">{{lang.total_include_vat}}</th>
                                <td style="width:25%;border:1px solid #ccc;text-align:center;padding:2px !important;height:10px !important;">{{invoice.ftotal}}</td>
                            </tr>
                        </thead>
                    </b-table-simple>
                </div>
                <div :style="`direction:`+lang.dir+`;display:none`" class="m-2">
                    <h6 class="h6title" style="font-weight:bolder;color:#fff !important;background:red !important">{{lang.additional_method}}</h6>
                    <b-table-simple>
                        <thead>
                            <tr>
                                
                                <th style="text-align:center;height:10px !important;padding:2px !important;">{{lang.adding_date}}</th>
                                <th style="text-align:center;height:10px !important;padding:2px !important;">{{lang.description}}</th>
                                <th style="text-align:center;height:10px !important;padding:2px !important;">{{lang.ammount}}</th>
                                <th style="text-align:center;height:10px !important;padding:2px !important;">{{lang.method}}</th>
                                <th style="text-align:center;height:10px !important;padding:2px !important;">{{lang.action}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in additional" :key="index">
                                
                                <td class="text-center" style="height:10px !important;padding:2px !important;">{{String(item.adding_date).substr(0,10)}}</td>
                                <td class="text-center" style="height:10px !important;padding:2px !important;">{{item.description}}</td>
                                <td class="text-center" style="height:10px !important;padding:2px !important;">{{item.ammount}}</td>
                                <td class="text-center" style="height:10px !important;padding:2px !important;" v-html="getaddName(item)"></td>
                                <td>
                                    <v-btn class="ma-1" style="height:25px;background:red;color:#FFF;width:70px"  @click="DeleteAdd( item,index )">{{$parent.lang.delete}}</v-btn>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <th>
                                <b-form-input class="inborder"
                                    v-model="neadd.adding_date" type="date"></b-form-input>
                            </th>
                            <th>
                                <b-form-input class="inborder"
                                    v-model="neadd.description"></b-form-input>
                            </th>
                            <th>
                                <b-form-input class="inborder"
                                    v-model="neadd.ammount"></b-form-input>
                            </th>
                            <th>
                                <b-input-group>
                                    <b-form-select class="selborder"
                                        v-model="neadd.method"
                                        :options="additOptions">
                                    </b-form-select>
                                    <b-input-group-addon style="margin-right:-20px;margin-top:5px">
                                        <i class="fas fa-arrow-down"></i>
                                    </b-input-group-addon>
                                </b-input-group>
                            </th>
                            <th class="text-center" style="width:70px;">
                                <v-btn class="ma-1" style="height:25px;background:green;color:#FFF;width:70px" @click="addAddit()">{{$parent.lang.add}}</v-btn>
                            </th>
                        </tfoot>
                    </b-table-simple>
                </div>
                <div :style="`white-space:pre-line;direction:`+lang.dir" class="m-2">
                    {{ notes }}
                </div>
                <div :style="`direction:`+lang.dir" class="m-2">
                    <h6 v-if="invoice.invtype != 3" class="h6title text-center" style="background:#000 !important;color:#fff !important;">{{lang.in_payments}}</h6>
                    <h6 v-else class="h6title text-center" style="background:#000 !important;color:#fff !important;">{{lang.out_payments}}</h6>
                    <b-table-simple>
                        <thead>
                            <tr>
                                <th style="text-align:center;height:10px !important;padding:2px !important;">{{lang.voutcher_no}}</th>
                                <th style="text-align:center;height:10px !important;padding:2px !important;">{{lang.payment_date}}</th>
                                <th style="text-align:center;height:10px !important;padding:2px !important;">{{lang.payment_time}}</th>
                                <th style="text-align:center;height:10px !important;padding:2px !important;">{{lang.payment_amount}}</th>
                                <th style="text-align:center;height:10px !important;padding:2px !important;">{{lang.payment_cash}}</th>
                                <th style="text-align:center;height:10px !important;padding:2px !important;">{{lang.payment_span}} / {{lang.payment_bank}}</th>
                                <th style="display:none;text-align:center;" v-if="showButtons && invoice.ftotal > 0">{{lang.action}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in invoice.res" :key="index">
                                <td class="text-center" style="height:10px !important;padding:2px !important;">{{item.voutcherid}}</td>
                                <td style="text-align:center;height:10px !important;padding:2px !important;">{{item.created_date}}</td>
                                <td style="text-align:center;height:10px !important;padding:2px !important;">{{item.created_date}}</td>
                                <td style="text-align:center;height:10px !important;padding:2px !important;">{{item.ftotal}}</td>
                                <td style="text-align:center;height:10px !important;padding:2px !important;">{{item.paycash}}</td>
                                <td style="text-align:center;height:10px !important;padding:2px !important;">{{item.payspan}}</td>
                                <td style="display:none;text-align:center;width:150px;height:10px !important;padding:2px !important;" v-if="showButtons && invoice.ftotal > 0">
                                    <v-btn v-if="invoice.ftotal > 0" class="ma-1" style="height:30px;background:red;color:#FFF;width:150px;" @click="printPDF(item.id,2)">{{lang.print_in_voucher}}</v-btn>
                                    <v-btn v-else class="ma-1" style="height:30px;background:red;color:#FFF;width:150px;" @click="printPDF(item.id,2)">{{lang.print_out_voucher}}</v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </div>
                <div :style="`direction:`+lang.dir" class="m-2">
                    <invoice-expenses ref="getExpense" v-if="showDiscount" />
                </div>
                <div class="myRof" v-if="$store.state.licenseType.showSketch">
                    <div style="width:20%" v-for="(item,index) in invoice.images" :key="index">
                        <div class="backBlack text-center">{{ item.plate }}</div>
                        <div>
                            <img :src="`${item.image}`" style="width:100%" />
                        </div>
                    </div>
                </div>
            </template>
        </b-sidebar>
        <invPayment ref="invpayment" />
        <return-invoice ref="returnit" />
        <modify-invoice-note ref="modifynotes" />
        <sendInvoiceWhats ref="sendInvoiceWhats" />
    </div>
</template>

<script>
import axios from 'axios'
import invoiceExpenses from '@/components/invoice-expenses.vue';
import invPayment from '@/components/invPayment.vue'; 
import ReturnInvoice from './return-invoice.vue';
import {SnotifyPosition} from 'vue-snotify'
import modifyInvoiceNote from '@/components/modifyInvoiceNote.vue'
import sendInvoiceWhats from '@/components/sendInvoiceWhats.vue'
export default {
    components: { invoiceExpenses,invPayment,ReturnInvoice,modifyInvoiceNote,sendInvoiceWhats },
    data() {
        return {
            showButtons: true,
            invid: 0,
            invoice: {
                cars_info: [],
                inv_date: '',
                images: []
            },
            neadd:{
                adding_date: '',
                description: '',
                ammount: '',
                method: 1,
            },
            additOptions: [
                {text: 'خصم' , value: 1},
                // {text: 'اضافة' , value: 2},
            ],
            additional: [],
        }
    },
    created() {
        this.getInvoiceAdd();
    },
    computed:{
        notes: function(){
            let t = this.invoice.notes;
            // if(t != '' && typeof this.invoice.notes !== 'undefined')
            //     t = t.replace("\n","")

            return t;
        },
        invToDis: function (){
            
            let t = {
                total: 0,
                discount:0
            }
            if(isNaN(this.invoice.discount)){
                t.discount = 0;
            }else{
                t.discount = this.invoice.discount
            }
            // 
            t.total = parseFloat(this.invoice.discount) + parseFloat(this.invoice.total)
            return t;
        },
        showDiscount: function(){
            return false;
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        returntotal: function(){
            let total = 0;
            if(this.selItems.length > 0){
                for(let i=0;i<this.selItems.length;i++){
                    total = parseFloat(total) + parseFloat(this.selItems[i].ftotal);
                }
            }
            if(this.invoice.remain != 0 && this.invoice.remain != 'NaN'){
                total = this.invoice.paid;
            }
            return total;
        }
    },
    methods: {
        changeNote(){
            this.$refs.modifynotes.modifyIt({id: this.invoice.id, notes: this.invoice.notes})
        },
        custType(type){
            if(type == 2) {
                return this.$parent.lang.company_clinet
            }else if(type == 3){
                return this.$parent.lang.government_clinet
            }else{
                return this.$parent.lang.individual_client
            }
        },
        openWhatsAPP(){
            this.$refs.sendInvoiceWhats.invoice_id = this.invoice.id
            this.$refs.sendInvoiceWhats.number_list = this.invoice.mobile
        },
        DeleteAdd(item,i){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','delInvoiceAdd');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',item.id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;

                this.getInvoiceAdd();
            })
        },
        getInvoiceAdd(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getInvoiceAdd');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[invid]',this.invid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post

            ).then((response) => {
                const res = response.data;
                this.additional = res.results.data;
            })
        },
        getaddName(item){
            if(item.method == 1){
                return 'خصم';
            }else{
                return 'اضافة';
            }
        },
        addAddit(){
            let message = '';
            let valid = true;
            if(this.neadd.description == ''){
                message = "يرجى ادخال وصف العملية";
                valid = false;
            }
            else if(this.neadd.ammount == '' || this.neadd.ammount == 0){
                message = "يرجى ادخال وصف العملية";
                valid = false;
            }
            if(valid == false){
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','addInvoiceAdd');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[invid]',this.invid);
            post.append('data[adding_date]',this.neadd.adding_date);
            post.append('data[description]',this.neadd.description);
            post.append('data[ammount]',this.neadd.ammount);
            post.append('data[method]',this.neadd.method);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post

            ).then((response) => {
                const res = response.data;
                this.getInvoiceAdd();
            })
            // this.additional.push(this.neadd);
        },
        returnNow(){
            this.$refs.returnit.invid = this.invid;
            this.$refs.returnit.return_full_money = this.invoice.paid;
            this.$refs.returnit.invoice = this.invoice;
        },
        getInvoice(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getinvoicedet');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[invid]',this.invid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                this.invoice = res.results.data;
                this.mobile = this.invoice.mobile
                this.full_name = this.invoice.full_name
                this.amount = this.invoice.remain
                // this.$refs.getExpense.getexpense();
            }).then((response) => {
                // this.$refs.getExpense.getexpense();
            }).then(()=>{
                //this.$parent.getCurrentCards();
            });
        },
        readFile(url){
            let file = null;
            var request = new XMLHttpRequest();
            request.open('GET', url, true);
            request.responseType = 'blob';
            request.onload = function() {
                var reader = new FileReader();
                reader.readAsDataURL(request.response);
                reader.onload =  function(e){
                    file = e.target.result;
                };
            };
            request.send();
            return file;
        },
        printPDF (id,t)
        {
            // if(navigator.share){
            //     navigator.share({
            //         files: [new File([this.readFile('../api/print.php?invid='+id)],'file.pdf',{type: Blob.type})],
            //         title: '',
            //         text: '',
            //     })
            // }else{
                let pdfFrame = document.body.appendChild(document.createElement('iframe'));
                pdfFrame.style.display = 'none';
                pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
                if(t == 1)
                    pdfFrame.src = '../api/print.php?invid='+id;
                if(t == 2)
                    pdfFrame.src = '../api/print.php?recpid='+id;
            // }
        },
        printInvo(){
            window.open("../api/print.php?invid="+this.invid,"_blank");
        },
        invpayment(){
            
            this.$refs.invpayment.invid = this.invid;
            this.$refs.invpayment.amount1 = this.invoice.remain;
            this.$refs.invpayment.amount2 = this.invoice.remain;
            this.$refs.invpayment.mobile1 = this.invoice.mobile;
            this.$refs.invpayment.full_name1 = this.invoice.full_name;
        },
        hideMe(){
            document.getElementById('hideVinvo').click();
        }
    },
}
</script>
<style>
.myRof{
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items:center;
    align-items: center;
}
</style>